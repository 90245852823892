import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { AlertTitle, Box, IconButton } from '@mui/material';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React, { useEffect } from 'react';
import { DRAWER_WIDTH_CLOSE } from 'src/utils/constants/config';
import useSnackbarStore from 'src/zustand/useSnackbarStore';

const SnackbarGlobal = () => {
  const { open, dispatch, title, description, ...props } = useSnackbarStore();

  const onClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({
      open: false,
      sx: {},
    });
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        dispatch({
          open: false,
          sx: {},
        });
      }, 3000);
    }
  }, [open]);

  return (
    <Snackbar
      style={{
        left: 44 + DRAWER_WIDTH_CLOSE,
      }}
      sx={{
        zIndex: 10000,
        '& .MuiPaper-root': {
          pr: 4,
        },
      }}
      open={open}
      onClose={onClose}
    >
      <Box maxWidth={500}>
        <Alert {...props}>
          <AlertTitle>{title}</AlertTitle>
          {description}
          <IconButton
            onClick={() => {
              dispatch({
                open: false,
              });
            }}
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
            }}
            size="small"
            color="inherit"
          >
            <CloseOutlinedIcon sx={{ fontSize: 16 }} />
          </IconButton>
        </Alert>
      </Box>
    </Snackbar>
  );
};

export default SnackbarGlobal;

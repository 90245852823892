import { Theme, alpha } from '@mui/material';

const styleOverrideCheckbox = (theme: Theme) => ({
  '&.MuiCheckbox-colorPrimary': {
    color: theme.palette.text.disabled,
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
  },
  '&.MuiCheckbox-colorSecondary': {
    color: theme.palette.text.disabled,
    '&.Mui-checked': {
      color: theme.palette.secondary.main,
    },
  },
  '&.MuiCheckbox-colorError': {
    color: theme.palette.text.disabled,
    '&.Mui-checked': {
      color: theme.palette.error.main,
    },
  },
  '&.MuiCheckbox-colorWarning': {
    color: theme.palette.text.disabled,
    '&.Mui-checked': {
      color: theme.palette.warning.main,
    },
  },
  '&.MuiCheckbox-colorInfo': {
    color: theme.palette.text.disabled,
    '&.Mui-checked': {
      color: theme.palette.info.main,
    },
  },
  '&.MuiCheckbox-colorSuccess': {
    color: theme.palette.text.disabled,
    '&.Mui-checked': {
      color: theme.palette.success.main,
    },
  },
  '&.MuiCheckbox-colorInherit': {
    color: theme.palette.text.disabled,
    '&.Mui-checked': {
      color: alpha(theme.palette.action.active, 0.5),
    },
  },
  '&.Mui-disabled': {
    color: theme.palette.text.disabled,
  },
});

export default styleOverrideCheckbox;

import { Theme } from '@mui/material';

const styleOverrideRadio = (theme: Theme) => ({
  '&.MuiRadio-colorPrimary': {
    color: theme.palette.primary.main,
  },
  '&.MuiRadio-colorSecondary': {
    color: theme.palette.secondary.main,
  },
  '&.MuiRadio-colorError': {
    color: theme.palette.error.main,
  },
  '&.MuiRadio-colorWarning': {
    color: theme.palette.warning.main,
  },
  '&.MuiRadio-colorInfo': {
    color: theme.palette.info.main,
  },
  '&.MuiRadio-colorSuccess': {
    color: theme.palette.success.main,
  },
  '&.MuiRadio-colorDefault': {
    color: theme.palette.text.secondary,
  },
});

export default styleOverrideRadio;

import { Theme } from '@mui/material';

const styleOverrideTextField = (theme: Theme) => ({
  '& .MuiFilledInput-underline': {
    backgroundColor: theme.palette.text.disabled,
  },
  '& .MuiFilledInput-underline:after': {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  '& .MuiFilledInput-underline:before': {
    borderBottom: `1px solid ${theme.palette.text.disabled}`,
  },
  '& .MuiOutlinedInput-root': {
    fieldset: {
      borderColor: theme.palette.text.disabled,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-disabled fieldset': {
      borderColor: theme.palette.text.disabled,
    },
  },
  '& .MuiInput-root:before': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  '& .MuiInput-root:after': {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  '& .MuiInputBase-sizeSmall': {
    fontSize: '0.875rem',
    '& .MuiInputBase-input': {
      padding: '10px 14px',
    },
    '& .MuiInputBase-inputMultiline': {
      padding: 0,
    },
  },
});

export default styleOverrideTextField;

import { Theme, alpha } from '@mui/material/styles';

const styleOverrideButton = (theme: Theme) => ({
  //=================Contained=================//
  '&.MuiButton-colorPrimary': {
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.dark,
    },
    '&.MuiButton-contained.Mui-disabled': {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.light,
      opacity: 0.4,
    },
  },
  '&.MuiButton-colorTertiary': {
    color: theme.palette.common.white,
    background: theme.palette.tertiary.main,
    '&:hover': {
      background: theme.palette.tertiary.dark,
    },
    '&.MuiButton-contained.Mui-disabled': {
      color: theme.palette.common.white,
      background: theme.palette.tertiary.light,
      opacity: 0.4,
    },
  },
  '&.MuiButton-colorSecondary': {
    color: theme.palette.secondary.contrastText,
    background: theme.palette.secondary.main,
    '&:hover': {
      background: theme.palette.secondary.dark,
    },
    '&.MuiButton-contained.Mui-disabled': {
      color: theme.palette.secondary.contrastText,
      background: theme.palette.secondary.light,
      opacity: 0.4,
    },
  },
  '&.MuiButton-colorError': {
    color: theme.palette.error.contrastText,
    background: theme.palette.error.main,
    '&:hover': {
      background: theme.palette.error.dark,
    },
  },
  '&.MuiButton-colorWarning': {
    color: theme.palette.warning.contrastText,
    background: theme.palette.warning.main,
    '&:hover': {
      background: theme.palette.warning.dark,
    },
  },
  '&.MuiButton-colorInfo': {
    color: theme.palette.info.contrastText,
    background: theme.palette.info.main,
    '&:hover': {
      background: theme.palette.info.dark,
    },
  },
  '&.MuiButton-colorSuccess': {
    color: theme.palette.success.contrastText,
    background: theme.palette.success.main,
    '&:hover': {
      background: theme.palette.success.dark,
    },
  },
  '&.MuiButton-colorInherit': {
    color: theme.palette.text.primary,
    background: theme.palette.grey[200],
    '&:hover': {
      background: theme.palette.grey[100],
    },
  },
  '&.MuiButton-contained.Mui-disabled': {
    color: theme.palette.action.disabled,
    background: theme.palette.action.disabledBackground,
    '&:hover': {
      background: theme.palette.grey[100],
    },
  },
  //=================Outline=================//
  '&.MuiButton-outlinedPrimary': {
    color: theme.palette.primary.main,
    background: 'transparent',
    '&:hover': {
      background: alpha(theme.palette.primary.main, 0.1),
    },
  },
  '&.MuiButton-outlinedSecondary': {
    color: theme.palette.secondary.main,
    background: 'transparent',
    '&:hover': {
      background: alpha(theme.palette.secondary.main, 0.1),
    },
  },
  '&.MuiButton-outlinedSuccess': {
    color: theme.palette.success.main,
    background: 'transparent',
    '&:hover': {
      background: alpha(theme.palette.success.main, 0.1),
    },
  },
  '&.MuiButton-outlinedError': {
    color: theme.palette.error.main,
    background: 'transparent',
    '&:hover': {
      background: alpha(theme.palette.error.main, 0.1),
    },
  },
  '&.MuiButton-outlinedWarning': {
    color: theme.palette.warning.main,
    background: 'transparent',
    '&:hover': {
      background: alpha(theme.palette.warning.main, 0.1),
    },
  },
  '&.MuiButton-outlinedInfo': {
    color: theme.palette.info.main,
    background: 'transparent',
    '&:hover': {
      background: alpha(theme.palette.info.main, 0.1),
    },
  },
  '&.MuiButton-outlinedInherit': {
    color: theme.palette.text.secondary,
    background: 'transparent',
    borderColor: theme.palette.divider,
    '&:hover': {
      background: theme.palette.action.hover,
    },
  },
  '&.MuiButton-outlined.Mui-disabled': {
    color: theme.palette.action.disabled,
    border: `1px solid ${theme.palette.action.disabledBackground}`,
  },
  //=================Text=================//
  '&.MuiButton-textPrimary': {
    color: theme.palette.primary.main,
    background: 'transparent',
    '&:hover': {
      background: alpha(theme.palette.primary.main, 0.1),
    },
  },
  '&.MuiButton-textSecondary': {
    color: theme.palette.secondary.main,
    background: 'transparent',
    '&:hover': {
      background: alpha(theme.palette.secondary.main, 0.04),
    },
  },
  '&.MuiButton-textError': {
    color: theme.palette.error.main,
    background: 'transparent',
    '&:hover': {
      background: alpha(theme.palette.error.main, 0.04),
    },
  },
  '&.MuiButton-textWarning': {
    color: theme.palette.warning.main,
    background: 'transparent',
    '&:hover': {
      background: alpha(theme.palette.warning.main, 0.04),
    },
  },
  '&.MuiButton-textInfo': {
    color: theme.palette.info.main,
    background: 'transparent',
    '&:hover': {
      background: alpha(theme.palette.info.main, 0.04),
    },
  },
  '&.MuiButton-textSuccess': {
    color: theme.palette.success.main,
    background: 'transparent',
    '&:hover': {
      background: alpha(theme.palette.success.main, 0.04),
    },
  },
  '&.MuiButton-textInherit': {
    color: theme.palette.text.secondary,
    background: 'transparent',
    '&:hover': {
      background: theme.palette.action.hover,
    },
  },
  '&.MuiButton-text.Mui-disabled': {
    color: theme.palette.action.disabled,
  },
});

export default styleOverrideButton;

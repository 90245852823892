import { Theme } from '@mui/material';
import colorSystem from 'src/scss/_colors-system.module.scss';

const styleOverrideSelect = (theme: Theme) => ({
  '& .MuiSelect-filled': {
    backgroundColor: colorSystem.neutroState100,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.divider,
  },
  '&.Mui-focused': {
    '&:after': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
});

export default styleOverrideSelect;

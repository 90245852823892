export const API_URI = process.env.REACT_APP_API_URL;
// App localStorage keys
export const REFRESH_TOKEN_KEY = 'REFRESH_TOKEN';
export const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN';
export const IP_ADDRESS_KEY = 'IP_ADDRESS_KEY';
export const DEVICE_ID_KEY = 'DEVICE_ID_KEY';

// Config Sidebar
export const DRAWER_WIDTH = 240;
export const DRAWER_WIDTH_CLOSE = 84;
export const DRAWER_Z_INDEX = 2000;
export const HEADER_HEIGHT = 81;
// export const FORMAT_TIME = 'DD-MM-YYYY'

// Constant
export const LENGTH_OTP_VALIDATE = 6;
export const MIN_LENGTH_PASSWORD = 12;
export const LIMIT_GET_LIST = 10;
export const EmptyText = '--';
export const EmptyDateTime = '--/--/----';
export const UnknownText = 'Unknown';
export const TEXT_REQUIRED = 'This field is required';
export const TEXT_REQUIRED_GREATER_THAN_0 = 'This field requires a value greater than 0';
export const DEFAULT_TIMER_VERIFICATION = 60 * 3;

export const FORMAT_DATE_TIME = {
  DD_MM_YYYY_h_mm_A: 'DD-MM-YYYY h:mmA',
  DD_MM_YYYY_h_mm: 'DD-MM-YYYY h:mm',
  DD_MM_YYYY_HH_mm: 'DD-MM-YYYY HH:mm',
  DD_MM_YYYY: 'DD-MM-YYYY',
  H_MM_A: 'h:mmA',
  H_MM_a: 'h:mma',
  UTC: 'DD-MM-YYYYTHH:mm:ss[Z]',
};

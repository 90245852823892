import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ReactDOM from 'react-dom/client';
import ErrorBoundary from 'src/components/ErrorBoundary';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import SnackbarGlobal from 'src/components/common/Snackbar';
import GlobalLoading from 'src/components/GlobalLoading';
import { queryClient } from 'src/configs/queryClient';
import ThemeCustomization from 'src/themes';
import './index.css';
import reportWebVitals from './reportWebVitals';

const LazyLoadingApp = React.lazy(() => import('src/App'));

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.Suspense fallback={<GlobalLoading />}>
    <BrowserRouter>
      <ErrorBoundary>
        <ThemeCustomization>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <QueryClientProvider client={queryClient}>
              {(process.env.NODE_ENV === 'development' && (
                <>
                  <LazyLoadingApp />
                  <ReactQueryDevtools initialIsOpen={false} />
                </>
              )) || <LazyLoadingApp />}
              <SnackbarGlobal />
            </QueryClientProvider>
          </LocalizationProvider>
        </ThemeCustomization>
      </ErrorBoundary>
    </BrowserRouter>
  </React.Suspense>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
